<template>
  <div>
    <b-modal
      id="modal-separate-booking"
      :title="$t('reservation.separateBooking')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      no-close-on-backdrop
      @show="showHandle"
    >
      <div class="text-heading-5 mb-1">
        {{ $t('reservation.separateBookingText') }}
      </div>

      <b-card v-if="getBookingData && getBookingData.source !== 'VU'">
        <b-form-group>
          <template #default="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="checkbox-group-cancel-flight"
              v-model="passengerToSeparate"
              :aria-describedby="ariaDescribedby"
              name="flights"
            >
              <div
                v-for="(passenger, indexPaxList) in sortListPassengerSeparate"
                :key="indexPaxList"
              >
                <b-form-checkbox
                  :value="passenger.paxId"
                  class="d-flex align-items-center py-1"
                  :disabled="!isMultiplePassenger || isDisabledSelectPassenger && !passengerToSeparate.some(p=> p === passenger.paxId)"
                >
                  <!-- @change="val => handleCheckPassenger(val, passenger)" -->
                  <!-- {{ !isMultiplePassenger }} =|= {{ isDisabledSelectPassenger }} == {{ !passengerToSeparate.some(p=> p === passenger.paxId) }} =|= {{ isDisabledSelectPassenger && !passengerToSeparate.some(p=> p === passenger.paxId) }} -->
                  <div class="d-flex align-items-center">
                    <span class="font-weight-bolder">
                      {{ `${passenger.lastName} ${passenger.firstName}` }}
                    </span>
                    <span class="pl-25">({{ $t(`reservation.${passenger.paxType}`) }})</span>
                  </div>

                  <div v-if="['ADULT', 'INFANT'].includes(passenger.paxType) && getHomie(passenger)">
                    <i class="text-warning font-weight-bold ml-50">
                      (<span>
                        {{ passenger.paxType === 'ADULT' ? 'Đi cùng em bé: ' : 'Đi cùng người lớn: ' }}
                      </span>
                      <span class="font-weight-bolder">
                        {{ `${getHomie(passenger).lastName} ${getHomie(passenger).firstName}` }}
                      </span>)
                    </i>
                  </div>
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
      </b-card>

      <div v-if="!isMultiplePassenger">
        <b-alert
          show
          variant="danger"
        >
          <div
            v-if="getBookingData && getBookingData.source !== 'VU'"
            class="alert-body"
          >
            Chỉ có thể tách hành khách với vé chưa bị huỷ và có từ hai hành khách trở lên. (không tính em bé)
          </div>
          <div
            v-else
            class="alert-body"
          >
            Tách hành khách đối với vé Vietravel, quý khách vui lòng gọi tổng đài để xử lý.
          </div>
        </b-alert>
      </div>

      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mt-lg-25  border-0"
          :disabled="!passengerToSeparate.length"
          pill
          @click="submitSeparateBooking"
        >
          <span class="align-middle">{{ $t('reservation.separateBooking') }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BAlert, BCard, BButton,
} from 'bootstrap-vue'
import {
  ref, computed,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line no-unused-vars
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'
import { getUserData } from '@/api/auth/utils'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BAlert, BCard, BButton,
  },
  setup() {
    const toast = useToast()

    const {
      getBookingData,
      separateBooking,
    } = useReservationHandle()

    const passengerToSeparate = ref([])
    const listPassengerSeparate = ref([])

    const isMultiplePassenger = computed(() => {
      let result = true
      if (!getBookingData.value.source.includes('1S')) {
        result = (getBookingData.value.paxLists.filter(p => p.paxType !== 'INFANT').length > 1) && getBookingData.value.status !== 'CANCEL'
      }

      // FIXME - nếu vé đã PAID

      return result
    })

    const isDisabledSelectPassenger = computed(() => {
      let result = true
      if (!getBookingData.value.source.includes('1S')) {
        result = (passengerToSeparate.value.length + 1) === getBookingData.value.paxLists.filter(p => p.paxType !== 'INFANT').length
      } else {
        result = (passengerToSeparate.value.length + 1) === getBookingData.value.paxLists.length
      }

      return result
    })

    function sortByPaxType(a, b) {
      const paxTypeOrder = ['ADULT', 'CHILD', 'INFANT']
      const aIndex = paxTypeOrder.indexOf(a.paxType)
      const bIndex = paxTypeOrder.indexOf(b.paxType)

      if (aIndex < bIndex) {
        return -1 // a sẽ đứng trước b
      } if (aIndex > bIndex) {
        return 1 // b sẽ đứng trước a
      }
      return 0 // không thay đổi vị trí
    }

    const sortListPassengerSeparate = computed(() => {
      let result = []
      if (!isEmpty(listPassengerSeparate.value)) {
        result = listPassengerSeparate.value.sort(sortByPaxType)
      }
      return result || []
    })

    function showHandle() { // for do something when open modal
      passengerToSeparate.value = []
      listPassengerSeparate.value = []

      if (getBookingData.value.source.includes('1S')) {
        getBookingData.value.paxLists.forEach(p => listPassengerSeparate.value.push(p))
      } else {
        getBookingData.value.paxLists.filter(p => p.paxType !== 'INFANT').forEach(p => listPassengerSeparate.value.push(p))
      }
    }

    function getHomie(passenger) {
      let homie = null

      if (passenger.paxType === 'ADULT') {
        homie = getBookingData.value.paxLists.find(p => p.parentPaxId === passenger.paxId)
      }

      if (passenger.paxType === 'INFANT') {
        homie = getBookingData.value.paxLists.find(p => p.paxId === passenger.parentPaxId)
      }

      return homie
    }

    function checkPassengerForVN(separateArr, passengerArr) {
      let result = false
      const listPassengerSelected = passengerArr.filter(p => separateArr.includes(p.paxId))

      // list passenger full info selected
      if (listPassengerSelected.length > 0) {
        // parrent đã chọn
        const parentList = listPassengerSelected.filter(p => p.paxType === 'ADULT')

        // infant trong booking
        const infantOfBookingList = passengerArr.filter(p => p.paxType === 'INFANT')

        // infant đã chọn
        const infantList = listPassengerSelected.filter(p => p.paxType === 'INFANT')

        // Check nếu separateArr có ADT và có INF có ADT đã chọn chưa
        if (parentList.length > 0 && infantOfBookingList.length > 0) {
          const parentPaxIdArr = parentList.map(p => p.paxId)
          const hasSelectedInfantArr = infantOfBookingList.filter(p => parentPaxIdArr.includes(p.parentPaxId)).map(p => p.paxId)
          const selectedAll = hasSelectedInfantArr.every(p => separateArr.includes(p))

          if (!selectedAll) {
            result = true
          }
        }

        if (infantList.length > 0) {
          if (isEmpty(parentList)) {
            result = true
          }

          // list parentPaxIdSelected
          const parentPaxIdSelectedArr = infantList.map(i => i.parentPaxId)

          // check INF đã chọn có đúng và đủ ADT đi cùng
          const parentSelectedArr = parentList.filter(p => parentPaxIdSelectedArr.includes(p.paxId))

          if (parentPaxIdSelectedArr.length !== parentSelectedArr.length) {
            result = true
          }
        }
      }

      return result
    }

    function submitSeparateBooking(bvModalEvent) {
      bvModalEvent.preventDefault()

      let paxId
      let isValid = true
      let errMsg = ''

      if (getBookingData.value.source.includes('1S') && getBookingData.value.status.toUpperCase().includes('PAID')) {
        const policeResult = checkPassengerForVN(passengerToSeparate.value, getBookingData.value.paxLists)
        if (policeResult) {
          isValid = false
          errMsg = 'Vé đã thanh toán không thể tách riêng Người lớn và Em bé, vui lòng chọn lại.'
        } else {
          paxId = passengerToSeparate.value
        }
      } else if (getBookingData.value.source === 'VJ') {
        if (listPassengerSeparate.value.filter(p => p.paxType === 'ADULT').some(p => passengerToSeparate.value.includes(p.paxId))) {
          paxId = passengerToSeparate.value
        } else {
          isValid = false
          errMsg = 'Không thể tách riêng trẻ em. Trẻ em cần có ít nhất một người lớn đi cùng.'
        }
      } else {
        paxId = passengerToSeparate.value
      }

      const payload = {
        contact: getUserData().employeeData.id,
        agencyCode: getBookingData.value.agency,
        paxCode: getBookingData.value.paxContact.code,
        source: getBookingData.value.source,
        pnrNumber: getBookingData.value.bookingCode,
        bookingId: getBookingData.value?.bookingInfoId || null,
        paxId: ['VN1A_MT', 'VN1A'].includes(getBookingData.value.source) ? paxId.map(id => getBookingData.value?.paxLists.find(p => p?.paxId === id)?.nameId) : paxId,
      }

      if (isValid && payload.paxId) {
        // show modal confirm to modify
        const titleMsg = () => this.$createElement('div', {
          domProps: { innerHTML: this.$t('reservation.confirmSeparateBooking') },
        })
        this.$bvModal
          .msgBoxConfirm(titleMsg(), {
            title: this.$t('modal.confirm'),
            size: 'sm',
            okVariant: 'info',
            okTitle: this.$t('modal.yes'),
            cancelTitle: this.$t('modal.no'),
            cancelVariant: 'outline-danger',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              store.dispatch('app/setLoading', true)
              separateBooking(payload)
                .then(() => {
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('reservation.separateBookingSuccessful'),
                      icon: 'AlertTriangleIcon',
                      variant: 'success',
                    },
                  })
                })
                .catch(error => {
                  // errorCancel.value = error.response.data.message || error
                  console.error((error))
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('reservation.separateBookingError'),
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
                .finally(() => {
                  store.dispatch('app/setLoading', false)
                  this.$bvModal.hide('modal-separate-booking')
                })
            }
          })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: this.$t('reservation.errTitle'),
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            text: errMsg,
          },
        })
      }
    }

    return {
      getBookingData,
      sortListPassengerSeparate,
      showHandle,
      formatCurrency,
      submitSeparateBooking,
      passengerToSeparate,
      convertISODateTime,
      listPassengerSeparate,
      getHomie,
      isMultiplePassenger,
      isDisabledSelectPassenger,

      capitalize,
    }
  },
}
</script>
