var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-separate-booking",
      "title": _vm.$t('reservation.separateBooking'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.showHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "disabled": !_vm.passengerToSeparate.length,
            "pill": ""
          },
          on: {
            "click": _vm.submitSeparateBooking
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.separateBooking')))])])];
      }
    }])
  }, [_c('div', {
    staticClass: "text-heading-5 mb-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.separateBookingText')) + " ")]), _vm.getBookingData && _vm.getBookingData.source !== 'VU' ? _c('b-card', [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "id": "checkbox-group-cancel-flight",
            "aria-describedby": ariaDescribedby,
            "name": "flights"
          },
          model: {
            value: _vm.passengerToSeparate,
            callback: function callback($$v) {
              _vm.passengerToSeparate = $$v;
            },
            expression: "passengerToSeparate"
          }
        }, _vm._l(_vm.sortListPassengerSeparate, function (passenger, indexPaxList) {
          return _c('div', {
            key: indexPaxList
          }, [_c('b-form-checkbox', {
            staticClass: "d-flex align-items-center py-1",
            attrs: {
              "value": passenger.paxId,
              "disabled": !_vm.isMultiplePassenger || _vm.isDisabledSelectPassenger && !_vm.passengerToSeparate.some(function (p) {
                return p === passenger.paxId;
              })
            }
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "font-weight-bolder"
          }, [_vm._v(" " + _vm._s("".concat(passenger.lastName, " ").concat(passenger.firstName)) + " ")]), _c('span', {
            staticClass: "pl-25"
          }, [_vm._v("(" + _vm._s(_vm.$t("reservation.".concat(passenger.paxType))) + ")")])]), ['ADULT', 'INFANT'].includes(passenger.paxType) && _vm.getHomie(passenger) ? _c('div', [_c('i', {
            staticClass: "text-warning font-weight-bold ml-50"
          }, [_vm._v(" ("), _c('span', [_vm._v(" " + _vm._s(passenger.paxType === 'ADULT' ? 'Đi cùng em bé: ' : 'Đi cùng người lớn: ') + " ")]), _c('span', {
            staticClass: "font-weight-bolder"
          }, [_vm._v(" " + _vm._s("".concat(_vm.getHomie(passenger).lastName, " ").concat(_vm.getHomie(passenger).firstName)) + " ")]), _vm._v(") ")])]) : _vm._e()])], 1);
        }), 0)];
      }
    }], null, false, 4219479236)
  })], 1) : _vm._e(), !_vm.isMultiplePassenger ? _c('div', [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm.getBookingData && _vm.getBookingData.source !== 'VU' ? _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Chỉ có thể tách hành khách với vé chưa bị huỷ và có từ hai hành khách trở lên. (không tính em bé) ")]) : _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Tách hành khách đối với vé Vietravel, quý khách vui lòng gọi tổng đài để xử lý. ")])])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }